<template>
  <div>
    <v-container fuild>
      <v-row v-if="loaded">
        <v-col md="12" sm="12" class="upload" v-cloak :key="i">
          <v-card>
            <v-card-title> {{ uploadName }} : Step {{ i }} </v-card-title>
            <v-card-text>
              <div ref="img.`${i}`" style="border: 1px solid">
                <!--v-fab-transition v-if="imgData[i][0].url !== ''">
                <v-btn
                  icon
                  dark
                  absolute
                  top
                  right
                  color="red"
                  fab
                  small
                  @click="removeImage"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-fab-transition-->
                <img :src="imgData[i][0].url" />
              </div>
            </v-card-text>
            <v-card-text>
              <v-card>
                <v-card-title> </v-card-title>
                <v-card-text @drop.prevent="handleImage" @dragover.prevent :id="i">
                  <div class="doctorUpload" v-if="stepToComplete.find((val) => val == i)">
                    <span v-if="!imgDoctorData[i][0].url">Drop image here!</span>
                    <img :src="imgDoctorData[i][0].url" /></div
                ></v-card-text>
                <v-card-actions>
                  <v-file-input
                    v-model="image"
                    accept="image/png, image/jpeg, image/bmp"
                    prepend-icon="mdi-camera"
                    :label="uploadName"
                    @change="uploadInputImage"
                  ></v-file-input>
                </v-card-actions>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col> </v-row
    ></v-container>
  </div>
</template>
<script>
export default {
  name: "uploadMonitoring",
  props: {
    uploadName: {
      type: String,
    },
    uploadId: {
      type: String,
    },
    imgData: {
      type: Object,
    },
    imgDoctorData: {
      type: Object,
    },
    totalStep: {
      type: Number,
    },
    stepToComplete: {
      type: Array,
    },
    //i: { type: Number },
  },
  data() {
    return {
      image: "",
      url: process.env.VUE_APP_LOYALTY_API_URI,
      //file: [],
      i: 0,
      file: [],
      uploadImage: "",
      imgSrc: "",
      loaded: false,
    };
  },
  computed: {
    // imgUrl(id, index) {
    //   //  console.log("imgUrl");
    //   let img = this.imgData.filter((item) => {
    //     return item.Type == id && item.Step == index;
    //   });
    //   console.log(img);
    //   return img.url || "";
    // },
  },
  created() {},
  mounted() {
    //this.file.FileValue = this.imgData;
    // console.log("mounted");
    // console.log(this.uploadName);
    // console.log(this.imgData);
    // console.log(this.totalStep);
    // console.log(this.stepToComplete);

    //console.log(this.file);
    this.i = this.$route.params.step;
    this.loaded = true;
  },
  methods: {
    resetFile() {
      this.file[this.i] = [
        {
          FileName: "",
          FileType: "",
          FileValue: "",
          url: "",
        },
      ];
    },
    uploadInputImage(e) {
      console.log(e);
      console.log(typeof e);
      //console.log(e.target.file[0])
      //const uploadImage = e.target.files[0];
      //console.log(this.editedItem.image);
      if (typeof e != "undefined") {
        this.createInputBase64Image();
      } else {
        this.file = "";
      }
    },
    createInputBase64Image() {
      console.log("createInputBase64Image");
      const reader = new FileReader();
      console.log(this.image);
      reader.readAsDataURL(this.image);
      let f = this.image;
      // this.file = {
      //   FileName: f.name,
      //   FileType: f.type,
      //   FileValue: null,
      //   IsActive: true,
      // };
      this.file[this.i] = [
        {
          FileName: f.name,
          FileType: f.type,
          FileValue: "",
          url: "",
        },
      ];
      console.log(this.file);
      reader.onload = (e) => {
        //this.file.FileValue = e.target.result;
        this.file[this.i][0].FileValue = e.target.result;
        this.updateUpload();
        //console.log(e.target.result);
      };
      //console.log(reader.readAsDataURL(this.image));
    },
    handleImage(e) {
      console.log(e);
      console.log("handleImage");
      console.log(e.currentTarget.id);
      this.i = e.currentTarget.id;
      //console

      if (typeof e != "undefined") {
        this.createBase64Image(e);
      } else {
        this.file[this.i] = [
          {
            FileName: "",
            FileType: "",
            FileValue: "",
            url: "",
          },
        ];
      }
    },
    createBase64Image(e) {
      console.log("createBase64Image");

      let files = e.dataTransfer.files;
      console.log(this.i);

      let f = files[0];
      //console.log(f);
      let reader = new FileReader(f);
      //console.log(f);
      //this.file = [];
      this.file[this.i] = [
        {
          FileName: f.name,
          FileType: f.type,
          FileValue: "",
          url: "",
        },
      ];
      //console.log(this.file);
      reader.readAsDataURL(f);

      reader.onload = (e) => {
        this.file[this.i][0].FileValue = e.target.result;
        //this.imgData[this.i] = [{ url: e.target.result }];
        console.log(e);
        this.updateUpload();
      };
    },

    async updateUpload() {
      const regex = / /gi;
      //const path = this.$route.params.caseNo + "/step-upload";
      console.log("this.file");
      console.log(this.file[this.i]);
      let filename = this.file[this.i][0].FileName.replace(regex, "-");

      console.log(this.i);
      console.log("---------------------------");
      console.log(this.imgDoctorData);
      console.log("----------------------------");
      console.log(this.file);

      await this.$emit("updateUpload", {
        id: this.uploadId,
        index: this.i,
        file: this.file[this.i],
      });

      this.imgDoctorData[this.i] = [
        {
          FileName: filename,
          //FileType: this.file[this.i].FileType,
          //url: this.url + "/public/cases/" + path + "/" + filename,
          url: this.file[this.i][0].FileValue,
        },
      ];
    },
    removeImage() {
      this.resetFile();
      this.$emit("removeUpload", { id: this.uploadId, index: this.i });
    },
  },
  watch: {
    file: {
      handler() {
        //console.log(this.file[this.i]);
      },
    },
    stepToComplete: {
      handler(value) {
        console.log(value);
      },
    },
    imgDoctorData: {
      handler(value) {
        //
        console.log(value);
      },
    },
    imgData: {
      handler(value) {
        console.log("image Data");
        console.log(value);
        //this.loaded = true;

        //this.file[this.uploadName] = [];

        // for (let i = 0; i < this.totalStep; i++) {
        //   console.log(i);
        //   this.file[this.uploadName] = [];
        //   this.file[this.uploadName][i] = value.filter((item) => {
        //     console.log(item);
        //     console.log(this.uploadName);
        //     console.log(i);
        //     // eslint-disable-next-line no-unused-vars
        //     let obj = { FileName: "", url: "" };
        //     if (item.Type == this.uploadName && i == item.Step) {
        //       obj = {
        //         FileName: item.FileName,
        //         url: this.url + "/public/cases/" + item.url,
        //       };
        //     }
        //     return obj;
        //   });
        // }
        // this.file = value.map((item) => {
        //   console.log("item");
        //   console.log(item);
        //   let arr = [];
        //   arr[item.Type] = [];

        //   arr[item.Type][item.Step] = {
        //     FileName: item.FileName,
        //     url: item.url,
        //     FileValue: item.FileValue,
        //   };
        //   return arr;
        // });

        //console.log(this.file);

        /*
        for (let index in value) {
          // this.file[index] = {
          //   FileName: "",
          //   FileValue: "",
          //   FileType: "",
          //   url: "",
          // };
          if (typeof value[index].FileValue !== "undefined") {
            if (value[index].FileValue.indexOf("data:") > -1) {
              this.file[index].FileValue = value[index].FileValue;
              this.file[index].FileName = value[index].FileName;
            } else {
              if (value[index].FileName != "") {
                this.file[index].FileValue =
                  this.url + "/public/cases/" + value[index].url;
                this.file[index].FileName = value[index].FileName;
              } else {
                this.file[index].FileValue = "";
                this.file[index].FileName = "";
              }
            }

            // for (let i in value) {
            //   console.log(i);
            // }
          } else {
            console.log("empty value");

            this.file[index] = {
              FileName: "",
              FileValue: "",
              FileType: "",
              url: "",
            };
          }
        }

        console.log(this.file);
        */
      },
    },
  },
};
</script>
<style scope>
.upload {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 14px;
  display: inline-block;
  border: 1px solid #090909;
  color: #afafaf;
  font-size: 2rem;
  float: left;
  text-align: center;
}

.upload:hover {
  border: 1px solid red;
}

.upload img {
  clear: both;
  width: 90%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.close {
  position: absolute;
  background: red;
  color: white;
  top: -10px;
  right: -10px;
}

.doctorUpload {
  min-width: 200px;
  max-width: 100%;
  min-height: 200px;
  max-height: 100%;
  border: 1px solid #4949494;
  background: #d00d0d0;
}
</style>
