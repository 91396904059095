/* eslint-disable no-unused-vars */
<template>
  <!----->
  <div>
    <v-dialog v-model="uploading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Uploading
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <bfc-bar></bfc-bar>
    <lab-menu></lab-menu>
    <div style="height: 20px"></div>
    <v-app-bar color="gray" style="height: 48px">
      <v-breadcrumbs :items="breadcrumbs" style="margin-top: -16px">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div style="height: 20px"></div>

    <v-stepper v-model="e1">
      <v-stepper-header>
        <template>
          <v-stepper-step editable step="1" key="1-step">
            Upload Monitoring: Step {{ step }}
          </v-stepper-step>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <!-- Lab Upload -->
        <v-stepper-content key="1-content" step="1">
          <!--div fuild>
            <v-btn-toggle v-model="toggle_exclusive" mandatory>
              <v-btn
                v-for="(item, index) in aligner_toggle"
                :key="index"
                :value="index"
                color="blue lighten-4"
              >
                {{ item }}
              </v-btn>
            </v-btn-toggle>
          </!--div-->
          <div fuild>
            <v-row>
              <v-col
                v-for="(item, index) in eForm.labUpload"
                :key="index"
                :value="index"
                md="4"
                sm="6"
              >
                <div>
                  <div v-if="loaded">
                    <dragdrop-upload
                      v-bind:uploadName="index"
                      v-bind:uploadId="index"
                      v-bind:totalStep="eForm.totalStep"
                      v-bind:stepToComplete="eForm.stepToComplete.split(',')"
                      @updateUpload="updateUpload"
                      @removeUpload="removeLabUpload"
                      v-bind:imgData="eForm.labUpload[index]"
                      v-bind:imgDoctorData="eForm.stepUpload[index]"
                    ></dragdrop-upload>
                  </div></div
              ></v-col>
            </v-row>

            <v-row>
              <v-col></v-col>
            </v-row>
            <v-row>
              <v-col> <v-divider></v-divider></v-col>
            </v-row>
            <!--v-row>
              <v-col md="12" sm="12">
                <v-btn color="primary" @click="saveDoctorUpload"> Save Upload </v-btn>

                <v-btn color="cyan lighten-4" @click="backStep(1)"> Back </v-btn>
              </v-col>
            </v-row-->
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
//import dragdropdUpload from "../../library/dragdrop-upload/dragdrop-upload";
//import paymentComponent from "../payment/payment";
import uploadMonitoring from "../../library/doctor-upload-monitoring-step/doctor-upload-monitoring-step";
import SystemBar from "../../library/system-bar/system-bar";
import DoctorMenu from "../menubar/menubar";
import axios from "axios";

import { bfcPackage } from "../../config/config.js";

export default {
  components: {
    "dragdrop-upload": uploadMonitoring,
    "lab-menu": DoctorMenu,
    "bfc-bar": SystemBar,
  },
  data: () => {
    return {
      toggle_exclusive: 0,
      aligner_toggle: {
        //rightProfile: "Right Profile",
        //frontalRepose: "Frontal Repose",
        //frontalSmiling: "Frontal Smiling",
        anteriorFrontal: "Anterior Frontal",
        buccalRight: "Buccal Right",
        buccalLeft: "Buccal Left",
        occlusalUpper: "Occlusal Upper",
        occlusalLower: "Occlusal Lower",
      },

      loaded: false,
      step: "",

      packages: bfcPackage,
      hasError: false,
      caseId: "",
      caseNo: "",
      custId: "",
      doctorId: "",
      clinicId: "",
      url: process.env.VUE_APP_LOYALTY_API_URI,
      actionCase: "Create Draft",

      //rightProfile: "Right Profile",
      //frontalRepose: "Frontal Repose",
      //frontalSmiling: "Frontal Smiling",
      anteriorFrontal: "Anterior Frontal",
      buccalRight: "Buccal Right",
      buccalLeft: "Buccal Left",
      occlusalUpper: "Occlusal Upper",
      occlusalLower: "Occlusal Lower",
      // opg: "OPG",
      // latCeph: "Lat Ceph",
      // others: "Others",
      // others2: "Others 2",
      caseFormDental: [],

      e1: 1,
      nSteps: 2,
      teeth2extract_cbx: [],
      breadcrumbs: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/doctor/dashboard",
        },
        {
          text: "Upload Monitoring",
          disabled: true,
          href: "/doctor/",
        },
      ],
      uploading: false,
      eForm: {
        labUpload: {
          //rightProfile: {},
          //frontalRepose: {},
          //frontalSmiling: {},
          anteriorFrontal: {},
          buccalRight: {},
          buccalLeft: {},
          occlusalUpper: {},
          occlusalLower: {},
        },

        stepUpload: {
          //rightProfile: {},
          //frontalRepose: {},
          //frontalSmiling: {},
          anteriorFrontal: {},
          buccalRight: {},
          buccalLeft: {},
          occlusalUpper: {},
          occlusalLower: {},
        },
        totalStep: 0,
        stepToComplete: "",
      },
      requiredRules: [(v) => !!v || "This field is required"],
      uploadObject: {},
    };
  },
  created() {},
  mounted() {
    this.step = this.$route.params.step;
    this.init();

    // this.packages = bfcPackage;
    // console.log(this.packages);
  },
  computed: {
    ...mapGetters({
      //getAuth: "BFC/getAuth",
      //getDoctorInfo: "BFC/getDoctorInfo",
      //getCaseInfo: "BFC/getCaseInfo",
    }),
    info() {
      //console.log(this.$route.params.caseNo);
      return this.getCaseInfo(this.$route.params.caseNo);
    },
    caseData() {
      return this.eForm;
    },
    requiredFields() {
      return {
        totalStep: this.eForm.totalStep,
        stepToComplete: this.eForm.stepToComplete,
      };
    },
  },
  methods: {
    ...mapActions({
      signout: "BFC/Logout",
      ///createDraft: "BFC/createDraftCase",
      //removeImage: "BFC/removeDoctorDraftImage",
    }),

    async init() {
      this.breadcrumbs.push({
        text: "Case : " + this.$route.params.caseNo,
        disabled: false,
        href: "/doctor/update-aligner/" + this.$route.params.caseNo,
      });

      try {
        //let payload
        await axios
          .post(
            this.url + "/api/bfc/v1/case/caseInfo",
            { caseNo: this.$route.params.caseNo }, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              let info = response.data.data[0];
              this.caseId = info._id;
              this.doctorId = info.doctorId;
              this.custId = info.custId;

              let labUpload = {};
              // eslint-disable-next-line no-unused-vars
              for (let key of Object.keys(this.eForm.labUpload)) {
                labUpload[key] = {};
                // eslint-disable-next-line no-unused-vars

                for (let i = 1; i <= info.totalStep; i++) {
                  labUpload[key][i] = info.labUpload
                    .filter((item) => {
                      // eslint-disable-next-line no-unused-vars

                      return item.Type == key && i == item.Step;
                    })
                    .map((item2Map) => {
                      return {
                        FileName: item2Map.FileName,
                        url: this.url + "/public/cases/" + item2Map.url,
                      };
                    });
                  if (labUpload[key][i].length == 0) {
                    labUpload[key][i] = [{ FileName: "", url: "" }];
                  }
                }
              }
              console.log(labUpload);
              info.labUpload = labUpload;

              let stepUpload = {};
              if (typeof info.stepUpload === "undefined") {
                info.stepUpload = [];
              }
              // eslint-disable-next-line no-unused-vars
              for (let key of Object.keys(this.eForm.stepUpload)) {
                stepUpload[key] = {};
                // eslint-disable-next-line no-unused-vars

                for (let i = 1; i <= info.totalStep; i++) {
                  stepUpload[key][i] = info.stepUpload
                    .filter((item) => {
                      // eslint-disable-next-line no-unused-vars

                      return item.Type == key && i == item.Step;
                    })
                    .map((item2Map) => {
                      return {
                        FileName: item2Map.FileName,
                        url: this.url + "/public/cases/" + item2Map.url,
                      };
                    });
                  if (stepUpload[key][i].length == 0) {
                    stepUpload[key][i] = [{ FileName: "", url: "" }];
                  }
                }
              }
              console.log(stepUpload);
              info.stepUpload = stepUpload;

              this.eForm = info;

              this.loaded = true;

              console.log(this.eForm);
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
    logout() {
      this.signout();
      this.$router.push("/lab");
    },

    updateUpload(val) {
      console.log(val);
      this.uploadObject = {};
      this.uploadObject[val.id] = {};
      this.uploadObject[val.id][val.index] = val.file;
      console.log(this.uploadObject);
      this.uploading = true;
      this.instantUpload();
    },
    removeLabUpload(val) {
      console.log(val);
      this.eForm.stepUpload[val.id][val.index] = [
        {
          FileName: "",
          FileType: "",
          FileValue: "",
          Url: "",
        },
      ];
      //this.removeImage(val);
      //console.log(this.getDraft);
      //this.remove(this.eForm);
      console.log(this.eForm);
    },

    async instantUpload() {
      try {
        let payload = {
          caseId: this.caseId,
          path: this.$route.params.caseNo + "/step-upload",

          stepUploadData: this.uploadObject,
        };
        //let hasError = false;

        console.log(payload);
        //console.log(selectPackage);

        await axios
          .post(
            this.url + "/api/bfc/v1/doctor/stepUpload",
            // need to change
            payload,
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            console.log(response);
            if (response.data.statusCode == 0) {
              // alert("Saved!");
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //

        console.log(e);
      }
      this.uploading = false;
    },

    //----------------------
    async saveDoctorUpload() {
      try {
        let payload = {
          caseId: this.caseId,
          path: this.$route.params.caseNo + "/step-upload",
          case: {
            totalStep: this.eForm.totalStep,
            stepToComplete: this.eForm.stepToComplete,
          },
          stepUploadData: this.eForm.stepUpload,
        };
        //let hasError = false;

        console.log(payload);
        //console.log(selectPackage);

        await axios
          .post(
            this.url + "/api/bfc/v1/doctor/stepUpload",
            // need to change
            payload,
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            console.log(response);
            if (response.data.statusCode == 0) {
              alert("Saved!");
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //

        console.log(e);
      }
    },
  },
  watch: {
    caseData() {
      //console.log();
      console.log("--------------------");
      console.log(this.caseData);
    },
    eForm: {
      //console.log();
      handler(val) {
        console.log(val);
        console.log("--------------------");
        //console.log(this.eForm.labUpload);
      },
    },
  },
};
</script>
<style scope type="css">
.bfc_li_teeth2extract,
.bfc_li_teeth2extract li {
  font-size: 0.97em;
  display: inline;
}

.bfc_li_teeth2extract_cbx {
  margin-left: 5.4px;
}

.bfc_li_RelieveCrowdingByStripping {
  display: inline;
  margin-left: 175px;
}

.bfc_li_RelieveCrowdingByStripping.mandible {
  display: inline;
  margin-left: 210px;
}

.bfc_li_RelieveCrowdingByStripping li {
  font-size: 0.97em;
  display: inline;
}

.bfc_li_RelieveCrowdingByStripping_cbx {
  /*padding-left: 20px;*/
}
.padding14 {
  padding-left: 14px;
}
.padding16 {
  padding-left: 16px;
}

.padding19 {
  padding-left: 19px;
}

.padding18 {
  padding-left: 19px;
}

.padding22 {
  padding-left: 22px;
}

.padding24 {
  padding-left: 24px;
}

.padding26 {
  padding-left: 26px;
}

.padding30 {
  padding-left: 30px;
}

/************/
.attachment {
  position: relative !important;
  display: block !important;
  width: 220px !important;
  height: auto;
  float: left;
}
.attachment ul {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  list-style-position: outside;
  float: left;
  margin-block-start: em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.attachment li {
  font-size: 0.97em !important;
  padding-left: 6px;
}
.attachment li:nth-child(2n-1) {
  background-color: #dfdfdf;
  width: 100%;
}

.quadrant {
  position: relative !important;
  display: inline-block !important;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  list-style-position: outside;
  float: left;
}
.quadrant li {
  font-size: 0.97em !important;
  text-align: center;
  margin-left: -32px;
  margin-right: 32px;
}
.uploadForm {
  display: block;
  width: 100% !important;
}
</style>
